<template>
  <v-container>        
    <lo-gin v-if="!forgetPassword"/>
    <forget-password v-else />
      <p class="text-center mt-3">
        <v-btn color="primary" small  @click="forgetPassword = !forgetPassword">{{ forgetPassword ? 'login' : 'forgot password'}}</v-btn>
      </p>
      
  </v-container>
</template>

<script>
import ForgetPassword from '@/components/ForgetPassword.vue';
import LoGin from '@/components/LoGin.vue';

export default {
  name: "Home",
  
  components: {
    ForgetPassword,
    LoGin
  },
  data() {
    return {
      forgetPassword:false,
      showLogin: false,
    };
  }
};
</script>
